body {
  padding: 0;
  // font-family: 'Lato',verdana, sans-serif;
}

.main-wrapper {
  // position:relative;
  img {
    display: block;
    position: relative;
    margin: 0 auto;
    height: 200px; //100vh;
    // width:100vw;
  }
}
.title-con {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    text-align: center;
    font-size: 3rem;
    color: #3c658e;
    letter-spacing: -0.15rem;
    margin: 15px 0;
  }
  h2 {
    // position: relative;
    font-size: 17px;
    font-weight: bold;
    margin: 0 auto;
    color: #3c658e;
  }
}

.helvetica {
  // font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  //  font-weight: 300;
}
.arial {
  font-family: Arial, sans-serif;
}
